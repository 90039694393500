<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>
          <i class="fa fa-table" aria-hidden="true"></i>
          {{ $t("groupSchedule.roster.title") }}
        </h1>
      </div>
    </div>
    <div class="section-action">
      <v-col>
        <!-- <v-col cols="12" md="12" lg="12"> -->
        <v-row>
          <v-col cols="12" md="6" lg="2">
            <label class="label-input">{{
              $t("groupSchedule.roster.rosterOption.date.startDate")
            }}</label>
            <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
              :append-to-body="true" name="founding_date" v-model="start_date"
              @change="handleEventFilterDate($event, 'start')"></DatePicker>
          </v-col>
          <v-col cols="12" md="6" lg="2">
            <label class="label-input">{{
              $t("groupSchedule.roster.rosterOption.date.endDate")
            }}</label>
            <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
              :append-to-body="true" v-model="end_date" @change="handleEventFilterDate($event, 'end')"
              name="founding_date">
            </DatePicker>
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <label class="label-input">{{ $t("name") }}/{{ $t("id") }}</label>
            <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense
              prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')" v-model="searchItem"
              @keypress.enter="searchFilterItem">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <label class="label-input">{{ $t("Employee.dept") }}</label>
            <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment" hide-selected
              v-model="department_id" @change="fetchData">
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <label class="label-input">{{ $t("Employee.branch") }}</label>
            <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
              hide-selected v-model="branch_id" @change="fetchData">
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <div class="btn-new-create-action">

            <v-btn class="btn-create" style="margin-top: 1rem;" @click="onCreate()">
              {{ $t("groupSchedule.roster.addEmp") }}
            </v-btn>
          </div>
        </v-row>
        <!-- <v-row> -->
        <!-- <v-col cols="12" md="4" lg="4">
              <label class="label-input">{{ $t("name") }}/{{ $t("id") }}</label>
              <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense
                prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')" v-model="searchItem"
                @keypress.enter="searchFilterItem">
              </v-text-field>
            </v-col> -->
        <!-- <v-col cols="12" md="4" lg="4">
              <label class="label-input">{{ $t("Employee.dept") }}</label>
              <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment"
                hide-selected v-model="department_id" @change="fetchData">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <label class="label-input">{{ $t("Employee.branch") }}</label>
              <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
                hide-selected v-model="branch_id" @change="fetchData">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col> -->
        <!-- </v-row> -->

        <!-- <v-col style="border: 2px solid red;justify-content: start; align-items: start;"> -->
        <div class="card-color-detail">
          <div class="color-detail">
            <div class="circle-color" :style="{ backgroundColor: workingBg }"></div>
            <p>{{$t('employee_roster.worksheet')}}</p>
          </div>
          <div class="color-detail">
            <div class="circle-color" :style="{ backgroundColor: weeklyBg }"></div>
            <p>{{ $t('employee_roster.week_day') }}</p>
          </div>
          <div class="color-detail">
            <div class="circle-color" :style="{ backgroundColor: leaveBg }"></div>
            <p>{{ $t('employee_roster.leave') }}</p>
          </div>
        </div>
        <!-- </v-col> -->
        <v-row>
          <v-btn class="success mt-2 ml-2 lfont" large @click="exportExcel"><span><i class="far fa-file-excel"></i> {{ $t('action.download_excel') }}</span>
          </v-btn>
          <v-btn class="info mt-2 ml-2" large @click="exportDutyRosterToPdf"><span><i class="far fa-file-pdf"></i>
              PDF</span>
          </v-btn>
          <v-btn class="info mt-2 ml-2" large @click="duplicateRoster"><span><i class="far fa-file-pdf"></i>
              Duplicate roster</span>
          </v-btn>
        </v-row>

        <!-- </v-col> -->

      </v-col>

    </div>
    <div class="section-form-content">
      <div class="container-report">
        <div class="card-report">
          <div>
            <table id="mytable">
              <thead :style="{
                color: 'white !important',
                borderBottom: '1px solid black',
                backgroundColor: dateBackgroundColor(),
              }">
                <th class="text-no-wrap" style="
                    color: white;
                    padding: 2px;
                    border: 1px solid black !important;
                    font-weight: 800;
                    text-align: center;
                  ">
                  Action
                </th>
                <th class="text-no-wrap" style="
                    color: white;
                    padding: 0px;
                    border: 1px solid black !important;
                    font-weight: 800;
                    width: 100px;
                  ">
                  {{ $t("ReportScanInOut.table.emp_number") }}
                </th>
                <th class="text-no-wrap" style="
                    padding: 2px;
                    font-weight: 800;
                    color: white;
                    border: 1px solid black !important;
                  ">
                  {{ $t("ReportScanInOut.table.fullName") }}
                </th>
                <th class="text-no-wrap" style="
                    color: white;
                    padding: 2px;
                    border: 1px solid black !important;
                    font-weight: 800;
                  ">
                  {{ $t("ReportScanInOut.table.dept") }}
                </th>
                <th class="text-no-wrap" :style="{
                  color: 'white',
                  paddingLeft: '2px',
                  paddingTop: '2px',
                  fontWeight: 600,
                  border: '1px solid black !important',
                }" v-for="(date, index) in dates" :key="index">
                  {{ formatDate(date.date) }} [{{ date.week_day }}]
                </th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in empData" :key="index">
                  <td>
                    <v-menu transition="slide-y-transition" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined v-bind="attrs" v-on="on" style="height: 18px; min-width: 40px">
                          <i class="fas fa-ellipsis-v"></i>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">{{ $t('action.delete') }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td>
                    {{ item.emp_number }}
                  </td>
                  <td style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    ">
                    {{ item.name }} {{ item.surname }}
                  </td>
                  <td>{{ item.dept_name }}</td>
                  <td v-for="(date, index) in dates" :key="index" class="emp-roster"
                    @click="addEmployeeRoster(date.date, item.id)" :style="{
                      backgroundColor: weeklyBgColor[date.divide_weekly],
                    }">
                    <div v-for="(roster_item, k) in getEmpWorkSheet(
                      date.date,
                      item.work_sheet,
                      item.weekly,
                      item.leaves
                    )" :key="k">
                      <v-chip :style="{
                        backgroundColor: chipBackgroundColor(
                          roster_item.type
                        ),
                        color: chipColor(roster_item.name),
                      }" class="ma-2" close color="red" @click:close="
                        removeEmpWorkSheet(
                          roster_item.id,
                          item.id,
                          roster_item.type
                        )
                        " v-if="roster_item.type != 'leave'">
                        {{ roster_item.name }}
                      </v-chip>
                      <v-chip :style="{
                        backgroundColor: chipBackgroundColor(
                          roster_item.type
                        ),
                        color: chipColor(roster_item.name),
                      }" class="ma-2" color="red" v-else>
                        {{ roster_item.name }}
                      </v-chip>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td v-for="(item, index) in dates" :key="index">
                    <p v-for="(work_sheet, i) in showSumWorkSheet(item.date)" :key="i" class="sum-work-sheet">
                      {{ work_sheet.name }} ({{ work_sheet.qty }}
                      <i class="far fa-user"></i>)
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset" @paginate="fetchData">
        </Pagination>
      </div>
    </div>
    <loading v-if="isLoading" />
    <modalEmployeeClockIn :visible="modalEmployeeClockIn" :date="addDate" :emp_id="empId" :allEmployee="allEmployee"
      @success="fetchData" @change="(val) => (modalEmployeeClockIn = val)" />
    <!-- <modalEmployeeClockIn :visible="modalEmployeeClockIn" :date="selectedDate" :emp_id="selectedEmpId"
      :allEmployee="allEmployee" @success="fetchData" @change="(val) => (modalEmployeeClockIn = val)" /> -->
    <modalDuplicateRoster :dialog="dialog_duplicate" @success="(val) => dialog_duplicate = val"
      @save="(val) => isLoading = val" @done="(val) => duplicate_done(val)" />
  </div>
</template>

<script>
import moment from "moment"; // import xlsx;
import Loading from "@/components/Loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Pagination from "@/components/Paginate/Pagination";
import modalEmployeeClockIn from "@/components/EmployeeRoster/modal/modalEmployeeClockIn";
import modalDuplicateRoster from "@/components/EmployeeRoster/modal/modalDuplicateRoster.vue";
export default {
  components: {
    btnLoading: false,
    Loading,
    DatePicker,
    Pagination,
    modalEmployeeClockIn,
    modalDuplicateRoster
  },
  data() {
    return {
      dialog_duplicate: false,
      listBranch: [],
      listDepartment: [],
      branch_id: "",
      department_id: "",
      sum_work_sheet: [],
      allEmployee: [],
      weeklyBgColor: ["#FEC6DF", "#F9FACD"],
      week: "",
      weekOfMonth: [],
      month: moment(new Date()).format("YYYY-MM"),
      rosterOptionChoose: "date",
      displayRosterOption: [
        { id: "date", name: "ວັນທີ່" },
        /* { id: "week", name: "ອາທິດ" }, */
      ],
      workingBg: "rgb(0, 85, 255)",
      weeklyBg: "rgb(225, 175, 39)",
      leaveBg: "red",
      addDate: "",
      empId: "",
      isLoading: false,
      searchItem: "",
      start_date: new Date().toISOString().slice(0, 10),
      end_date: new Date().toISOString().slice(0, 10),
      empData: {},
      dates: {},
      offset: 10,
      pagination: {},
      per_page: 10,
      modalEmployeeClockIn: false,
      // selectedDate: null,
      // selectedEmpId: null,
      // modalEmployeeClockIn: false,
    };
  },
  methods: {
    duplicateRoster() {
      this.dialog_duplicate = true;
    },
    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    duplicate_done(val) {
      this.start_date = val.start_date
      this.end_date = val.end_date
      this.fetchData()
    },
    exportDutyRosterToPdf() {
      this.isLoading = true;
      const item = {
        filter: this.searchItem,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$axios
        .get(`export/roster/employee/to/pdf`, {
          responseType: "arraybuffer",
          params: item,
        })
        .then((res) => {
          this.isLoading = false;
          const blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "duty-roster.pdf";
          link.click();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    showSumWorkSheet(date) {
      let work_sheet = this.sum_work_sheet.filter(function (el) {
        return el.date == date;
      });
      if (work_sheet.length > 0) {
        let return_value = [];
        work_sheet.forEach((item) => {
          return_value.push(item);
        });
        return return_value;
      } else {
        return [];
      }
    },
    dateBackgroundColor() {
      return "rgb(0, 85, 255, 0.8)";
    },
    getDateOfWeek() {
      let date = this.month;
      let year = moment(date).format("YYYY");
      this.start_date = moment(this.getFirstDateOfWeek(this.week, year)).format(
        "YYYY-MM-DD"
      );
      this.end_date = moment(this.getLastDateOfWeek(this.week, year)).format(
        "YYYY-MM-DD"
      );
    },
    exportExcel() {
      const item = {
        filter: this.searchItem,
        start_date: this.start_date,
        end_date: this.end_date,
        department_id: this.department_id,
        branch_id: this.branch_id,
      };
      this.isLoading = true;
      this.$axios
        .get(`export/roster/employee`, {
          responseType: "blob",
          params: item,
        })
        .then((res) => {
          this.isLoading = false;
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", "duty-roster.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    getFirstDateOfWeek(w, y) {
      var d = 1 + (w - 1) * 7 - 6; // 1st of January + 7 days for each week
      return new Date(y, 0, d);
    },
    getLastDateOfWeek(w, y) {
      var d = 1 + (w - 1) * 7; // 1st of January + 7 days for each week
      return new Date(y, 0, d);
    },
    getWeekOfMonth() {
      this.weekOfMonth = [];
      let date = this.month;
      let year = moment(date).format("YYYY");
      let month = moment(date).format("MM");
      let wm = this.weekCount(year, month);
      for (let i = 1; i <= wm; i++) {
        this.weekOfMonth.push(i);
      }
    },
    weekCount(year, month_number) {
      // month_number is in the range 1..12

      var firstOfMonth = new Date(year, month_number - 1, 1);
      var lastOfMonth = new Date(year, month_number, 0);

      var used = firstOfMonth.getDay() + lastOfMonth.getDate();

      return Math.ceil(used / 7);
    },
    chipColor(item) {
      return item == "weekly" ? "black !important" : "white !important";
    },
    chipBackgroundColor(item) {
      if (item == "weekly") {
        return this.weeklyBg + " !important";
      } else if (item == "leave") {
        return this.leaveBg + " !important";
      } else {
        return this.workingBg + " !important";
      }
    },
    getEmpWorkSheet(current_date, work_sheets, weeklys, leaves) {
      // ກວດສອບການຂໍລາພັກ
      let leaves_item = [];
      leaves.forEach((item) => {
        item.detail.forEach((detail_item) => {
          if (detail_item.date == current_date) {
            leaves_item.push({
              id: item.id,
              name: item.leave_name,
              type: "leave",
            });
          }
        });
      });
      if (leaves_item.length > 0) return leaves_item;

      let work_sheet = [];
      work_sheets.forEach((item) => {
        if (item.date == current_date) {
          work_sheet.push({
            id: item.id,
            name: item.schedule_detail_name,
            type: item.schedule_detail_name,
          });
        }
      });
      // ກວດສອບສົ່ງຄືນມື້ເຮັດວຽກ
      if (work_sheet.length > 0) return work_sheet;

      // ກວດສອບສົ່ງຄືນມື້ພັກວຽກ
      let weekly = [];
      weeklys.forEach((item) => {
        if (item == current_date) {
          weekly.push({ id: item, name: "weekly", type: "weekly" });
        }
      });
      return weekly;
    },
    removeEmpWorkSheet(id, emp_id, type) {
      this.$swal({
        title: "ຢືນຢັນການລຶບ ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d13737",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$axios
            .delete(
              `company/roster/delete/employee/clockin/${id}/${emp_id}/${type}`
            )
            .then((res) => {
              if (res.data.code === 200) {
                this.fetchData();
              } else if (res.data.code === 201) {
                this.$swal({
                  title: "ຢືນຢັນ ?",
                  text: "ມີການສະແກນແລ້ວ ທ່ານຕ້ອງການລຶບແທ້ ຫຼື ບໍ ?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d13737",
                  confirmButtonText: "Yes, Delete",
                }).then((result) => {
                  if (result.value) {
                    let confirm_del = true;
                    this.isLoading = true;
                    this.$axios
                      .delete(
                        `company/roster/delete/employee/clockin/${id}/${emp_id}/${type}/${confirm_del}`
                      )
                      .then((res) => {
                        this.fetchData();
                        this.isLoading = false;
                      })
                      .catch((error) => {
                        this.isLoading = false;
                        let message = error.response.data.message;
                        this.$notification.ShowErrors(
                          "top-right",
                          "danger",
                          3000,
                          message
                        );
                      });
                  }
                });
              }
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              let message = error.response.data.message;
              this.$notification.ShowErrors(
                "top-right",
                "danger",
                3000,
                message
              );
            });
        }
      });
    },
    addEmployeeRoster(date, empId) {
      this.modalEmployeeClockIn = true;
      this.addDate = date;
      this.empId = empId;
      console.log("this is me = " + date)//this is me = 2024-11-08
    },
    formatDate(date) {
      return moment(date).format("DD/MM");
    },
    searchFilterItem() {
      this.fetchData();
    },
    handleEventFilterDate(event, type) {
      if (type === "start") {
        this.start_date = event;
      } else if (type === "end") {
        this.end_date = event;
      }
      this.fetchData();
    },
    fetchData() {
      this.isLoading = true;
      this.$axios
        .get(`company/roster/list/employee`, {
          params: {
            per_page: this.per_page,
            page: this.pagination.current_page,
            filter: this.searchItem,
            start_date: this.start_date,
            end_date: this.end_date,
            department_id: this.department_id,
            branch_id: this.branch_id,
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.empData = res.data.data.employees.data;
            this.allEmployee = res.data.data.employees.data;
            this.sum_work_sheet = res.data.data.employees.sum_work_sheet;
            this.dates = res.data.data.current_date;
            this.pagination = res.data.data.employees.pagination;
            if (!this.empData.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    onCreate() {
      this.$router.push({ name: "roster.add.employee" }).catch(() => { });
    },
    onDeleted(emp_id) {
      this.isLoading = true;
      this.$axios
        .delete(`company/roster/delete/employee/${emp_id}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.fetchData();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
  created() {
    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.start_date = moment(firstDate).format("YYYY-MM-DD");
    this.end_date = moment(lastDate).format("YYYY-MM-DD");
    this.fetchData();
    this.getWeekOfMonth();
    this.fetchDepartment();
    this.fetchBranch();
    /* let startDate = new Date(date.getFullYear(), 0, 1);
    var days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));

    this.week = Math.ceil(days / 7) + 1; */
  },
};
</script>
<style scoped lang="scss">
.main-container {
  margin: 10px !important;
  padding-bottom: 0px !important;
}

.section-action {
  height: auto;
  margin-top: 20px;
}

.section-form-content {
  margin-top: 25px !important;
}

.highlight--schedule {
  .schedule--byEmployee {
    background-color: rgba(0, 168, 255, 0.15);
    color: #719aff;
    padding: 3px 12px;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }

  .schedule--byPosition {
    background-color: rgba(239, 87, 119, 0.2);
    color: #f82c80;
    padding: 3px 12px;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }
}

.container-report {
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 40px 0;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  border: 1px solid #eeeeee;

  h1 {
    font-family: $font-family;
    font-size: 1.5rem;
    background-color: #ffffff;
    color: blue;
  }

  .card-report {
    width: 100%;
    height: auto;
    overflow: hidden;
    overflow-x: auto;
    //background-color: #FFFFFF;
    padding: 15px 0;
    font-family: $font-family;
    background-color: #ffffff;
    margin-bottom: 5px;
  }
}

.card-color-detail {
  margin-bottom: 25px !important;
  display: flex;
  width: 100%;
  gap: 5px;
  // border: 2px solid blue;

  .color-detail {
    // width: 100%;
    height: 80%;
    display: flex;
    border: 1px solid #5c5c5c;
    align-items: center;
    border-radius: 16px;
    padding: 10px 10px;
    font-size: 14px !important;

    .circle-color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: red;
    }

    p {
      font-family: $font-family;
      margin-bottom: 0;
      padding-left: 20px;
    }

    .standard {
      background-color: #ffffff;
      border: 1px solid #000000;
    }

    .is-leave {
      background-color: #80acf4;
    }

    .is-absence {
      background-color: red;
    }

    .is-skip {
      background-color: #fd9644;
    }

    .is-time-difference {
      background-color: #2f3542;
    }
  }
}

#mytable {
  font-family: $font-family;
  text-transform: capitalize;
  font-size: 12px !important;
  border-collapse: separate;
  border-spacing: 0;

  td {
    border: 1px solid;
  }

  .roster-chip {
    color: white;
    background: rgb(225, 175, 39) !important;
  }

  .emp-roster:hover {
    background: rgb(212, 212, 212);
  }

  .sum-work-sheet {
    color: blue;
    border: 1px solid #eae9e9;
    text-align: center;
    padding: 5px;
  }

  .ma-2 {
    font-size: 12px !important;
    margin: 2px !important;
  }
}

//start freezed
#mytable th {
  position: sticky;
  top: 0;
  color: rgb(0, 159, 255);
  z-index: 1;
}

#mytable th:nth-child(1),
#mytable td:nth-child(1) {
  position: sticky;
  left: 0;
  min-width: 59px !important;
  z-index: 2 !important;
  background-color: #ffffff !important;
}

#mytable th:nth-child(2),
#mytable td:nth-child(2) {
  position: sticky;
  left: 59.5px;
  min-width: 73.5px !important;
  z-index: 2 !important;
  background-color: #ffffff !important;
}

#mytable th:nth-child(3),
#mytable td:nth-child(3) {
  position: sticky;
  left: 133.5px;
  min-width: 170.5px !important;
  z-index: 2 !important;
  background-color: #ffffff !important;
}

#mytable th:nth-child(4),
#mytable td:nth-child(4) {
  position: sticky;
  left: 303.4px;
  z-index: 2 !important;
  background-color: #ffffff !important;
}


#mytable th:nth-child(1),
#mytable th:nth-child(2),
#mytable th:nth-child(3),
#mytable th:nth-child(4) {
  background: rgb(0, 159, 255) !important;
}

#mytable td:nth-child(4),
#mytable th:nth-child(4) {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.75) !important;
  clip-path: inset(0px -15px 0px 0px) !important;
}

#mytable td:nth-child(4),
#mytable th:nth-child(4) {
  border-right: 1px solid black !important;
}

#mytable tbody tr:last-child td:nth-child(1),
#mytable tbody tr:last-child td:nth-child(2),
#mytable tbody tr:last-child td:nth-child(3),
#mytable tbody tr:last-child td:nth-child(4) {
  position: static !important;
  left: auto !important;
  z-index: auto !important;
  background-color: transparent !important;
  box-shadow: none !important;
  clip-path: none !important;
  border-right: none !important;
}

//end freezed

.td-part-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border: 1px solid #000000;
}
</style>
